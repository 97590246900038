import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, updatePassword, deleteUser, signOut, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import DashboardLayout from '../components/DashboardLayout';
import { User, Key, Mail, AlertTriangle, Save, Trash2, Info, CreditCard } from 'lucide-react';

const AccountSettings = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [userData, setUserData] = useState({
    email: ''
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/login');
      } else {
        setUserData({
          email: user.email || ''
        });
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handlePasswordChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value
    });
  };

  const getErrorMessage = (errorCode) => {
    const errorMessages = {
      'auth/wrong-password': 'Das aktuelle Passwort ist nicht korrekt',
      'auth/requires-recent-login': 'Bitte melde dich erneut an, um diese Aktion durchzuführen',
      'auth/weak-password': 'Das neue Passwort ist zu schwach. Bitte verwende mindestens 6 Zeichen',
      'auth/email-already-in-use': 'Diese E-Mail-Adresse wird bereits verwendet',
      'auth/invalid-email': 'Die E-Mail-Adresse ist ungültig',
      'auth/user-disabled': 'Dieses Konto wurde deaktiviert',
      'auth/user-not-found': 'Kein Konto mit dieser E-Mail-Adresse gefunden',
      'auth/too-many-requests': 'Zu viele Versuche. Bitte versuche es später erneut',
      'auth/operation-not-allowed': 'Diese Operation ist nicht erlaubt',
      'auth/network-request-failed': 'Netzwerkfehler. Bitte überprüfe deine Internetverbindung',
      'auth/missing-password': 'Bitte gib dein aktuelles Passwort ein',
      'auth/internal-error': 'Ein interner Fehler ist aufgetreten. Bitte versuche es erneut'
    };
    return errorMessages[errorCode] || `Ein Fehler ist aufgetreten (${errorCode}). Bitte versuche es erneut.`;
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Validierung der Eingaben
    if (!passwordData.currentPassword) {
      setError('Bitte gib dein aktuelles Passwort ein');
      return;
    }

    if (!passwordData.newPassword) {
      setError('Bitte gib ein neues Passwort ein');
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError('Die Passwörter stimmen nicht überein');
      return;
    }

    if (passwordData.newPassword.length < 6) {
      setError('Das neue Passwort muss mindestens 6 Zeichen lang sein');
      return;
    }

    try {
      if (!auth.currentUser) {
        setError('Du musst angemeldet sein, um dein Passwort zu ändern');
        return;
      }

      // Reauthorize user before password change
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        passwordData.currentPassword
      );

      try {
        await reauthenticateWithCredential(auth.currentUser, credential);
      } catch (reAuthError) {
        console.error('ReAuth Error:', reAuthError);
        setError(getErrorMessage(reAuthError.code));
        return;
      }
      
      // Update password
      try {
        await updatePassword(auth.currentUser, passwordData.newPassword);
        setSuccess('Passwort erfolgreich aktualisiert');
        setPasswordData({
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
      } catch (updateError) {
        console.error('Update Error:', updateError);
        setError(getErrorMessage(updateError.code));
      }
    } catch (error) {
      console.error('General Error:', error);
      setError(getErrorMessage(error.code));
    }
  };

  const handleCancelSubscription = async () => {
    try {
      // Hier Implementation für Abo-Kündigung einfügen
      // z.B. API-Call an Backend/Stripe
      setSuccess('Abonnement erfolgreich gekündigt');
      setShowCancelConfirm(false);
    } catch (error) {
      setError('Fehler beim Kündigen des Abonnements');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteUser(auth.currentUser);
      await signOut(auth);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <DashboardLayout>
      <div className="max-w-4xl mx-auto">
        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
            {error}
          </div>
        )}
        {success && (
          <div className="mb-4 p-4 bg-green-50 border border-green-200 text-green-700 rounded-lg">
            {success}
          </div>
        )}

        <h2 className="text-3xl font-bold text-amber-900 mb-6">Kontoeinstellungen</h2>

        {/* Persönliche Daten - Nur Anzeige */}
        <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6 mb-6">
          <div className="flex items-center gap-3 mb-6">
            <User className="w-6 h-6 text-amber-600" />
            <h3 className="text-xl font-bold text-amber-900">Persönliche Daten</h3>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-amber-700 mb-2">E-Mail-Adresse</label>
              <div className="px-4 py-2 bg-gray-50 rounded-lg border border-amber-200">
                {userData.email}
              </div>
            </div>
          </div>
        </div>

        {/* Abonnement */}
        <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6 mb-6">
          <div className="flex items-center gap-3 mb-6">
            <CreditCard className="w-6 h-6 text-amber-600" />
            <h3 className="text-xl font-bold text-amber-900">Abonnement</h3>
          </div>

          {!showCancelConfirm ? (
            <div>
              <p className="text-gray-700 mb-6">
                Dein aktives Abonnement kannst du hier kündigen. Die Kündigung wird zum Ende der aktuellen Abrechnungsperiode wirksam.
              </p>
              <button
                onClick={() => setShowCancelConfirm(true)}
                className="px-6 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200"
              >
                Abonnement kündigen
              </button>
            </div>
          ) : (
            <div>
              <p className="text-amber-700 mb-4">
                Möchtest du dein Abonnement wirklich kündigen?
              </p>
              <div className="flex gap-4">
                <button
                  onClick={() => setShowCancelConfirm(false)}
                  className="px-6 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-500 transition-colors duration-200"
                >
                  Abbrechen
                </button>
                <button
                  onClick={handleCancelSubscription}
                  className="px-6 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200"
                >
                  Kündigen bestätigen
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Passwort ändern */}
        <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6 mb-6">
          <div className="flex items-center gap-3 mb-6">
            <Key className="w-6 h-6 text-amber-600" />
            <h3 className="text-xl font-bold text-amber-900">Passwort ändern</h3>
          </div>
          
          <form onSubmit={handleUpdatePassword} className="space-y-4">
            <div>
              <label className="block text-amber-700 mb-2" htmlFor="currentPassword">
                Aktuelles Passwort
              </label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={passwordData.currentPassword}
                onChange={handlePasswordChange}
                className="w-full px-4 py-2 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
              />
            </div>

            <div>
              <label className="block text-amber-700 mb-2" htmlFor="newPassword">
                Neues Passwort
              </label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handlePasswordChange}
                className="w-full px-4 py-2 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
              />
            </div>

            <div>
              <label className="block text-amber-700 mb-2" htmlFor="confirmPassword">
                Neues Passwort bestätigen
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handlePasswordChange}
                className="w-full px-4 py-2 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
              />
            </div>

            <div className="flex justify-end">
              <button 
                type="submit"
                className="px-6 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200 flex items-center gap-2"
              >
                <Save className="w-4 h-4" />
                <span>Passwort speichern</span>
              </button>
            </div>
          </form>
        </div>

        {/* Konto löschen */}
        <div className="bg-red-50 rounded-xl shadow-lg border border-red-200 p-6">
          <div className="flex items-center gap-3 mb-6">
            <AlertTriangle className="w-6 h-6 text-red-600" />
            <h3 className="text-xl font-bold text-red-900">Konto löschen</h3>
          </div>

          {!showDeleteConfirm ? (
            <div>
              <p className="text-red-700 mb-6">
                Wenn du dein Konto löschst, werden alle deine Daten und Einstellungen unwiderruflich gelöscht. 
                Dein Abonnement wird zum Ende der aktuellen Laufzeit gekündigt.
              </p>
              <button
                onClick={() => setShowDeleteConfirm(true)}
                className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors duration-200 flex items-center gap-2"
              >
                <Trash2 className="w-4 h-4" />
                <span>Konto löschen</span>
              </button>
            </div>
          ) : (
            <div>
              <p className="text-red-700 mb-4">
                Bist du sicher, dass du dein Konto löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.
              </p>
              <div className="flex gap-4">
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-6 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-500 transition-colors duration-200"
                >
                  Abbrechen
                </button>
                <button
                  onClick={handleDeleteAccount}
                  className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors duration-200 flex items-center gap-2"
                >
                  <Trash2 className="w-4 h-4" />
                  <span>Endgültig löschen</span>
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Datenschutz-Info */}
        <div className="mt-6 bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-start gap-3">
          <Info className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
          <p className="text-blue-700 text-sm">
            Wir behandeln deine persönlichen Daten vertraulich und in Übereinstimmung mit den Datenschutzbestimmungen. 
            Mehr Informationen findest du in unserer Datenschutzerklärung.
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AccountSettings;