import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Book, Sparkles, Heart, Mail, ShoppingBag, X, Send, Brain, Globe, Cpu, Box, Zap, Wifi, Rocket, Castle, TreeDeciduous } from 'lucide-react';

// Language constants
const storyExamples = {
  de: [
    {
      category: "Weltraum",
      title: "Abenteuer auf der Raumstation Alpha",
      preview: "Entdecke mit Sarah und Tom die Geheimnisse der Schwerelosigkeit und lerne spannende Fakten über das Leben im All.",
      learningPoints: ["Schwerkraft", "Raumstationen", "Astronautenalltag"],
      icon: <Rocket className="w-12 h-12 text-amber-600" />
    },
    {
      category: "Mittelalter",
      title: "Der geheimnisvolle Burgschatz",
      preview: "Begleite Ritter Roland auf seiner Quest und erfahre, wie Menschen im Mittelalter lebten.",
      learningPoints: ["Burgleben", "Ritterkultur", "Mittelalterliche Berufe"],
      icon: <Castle className="w-12 h-12 text-amber-600" />
    },
    {
      category: "Natur",
      title: "Das Geheimnis des alten Waldes",
      preview: "Erkunde mit Emma die faszinierende Welt der Pflanzen und Tiere im Wald.",
      learningPoints: ["Ökosysteme", "Waldtiere", "Photosynthese"],
      icon: <TreeDeciduous className="w-12 h-12 text-amber-600" />
    }
  ],
  en: [
    {
      category: "Space",
      title: "Adventure on Space Station Alpha",
      preview: "Join Sarah and Tom as they discover the secrets of zero gravity and learn fascinating facts about life in space.",
      learningPoints: ["Gravity", "Space Stations", "Astronaut Life"],
      icon: <Rocket className="w-12 h-12 text-amber-600" />
    },
    {
      category: "Medieval",
      title: "The Mysterious Castle Treasure",
      preview: "Follow Knight Roland on his quest and learn how people lived in medieval times.",
      learningPoints: ["Castle Life", "Knight Culture", "Medieval Professions"],
      icon: <Castle className="w-12 h-12 text-amber-600" />
    },
    {
      category: "Nature",
      title: "The Secret of the Ancient Forest",
      preview: "Explore the fascinating world of plants and animals in the forest with Emma.",
      learningPoints: ["Ecosystems", "Forest Animals", "Photosynthesis"],
      icon: <TreeDeciduous className="w-12 h-12 text-amber-600" />
    }
  ]
};

  const translations = {
    de: {
      
      title: "Abenteuerkiste",
    subtitle: "Entdecke & lerne durch spannende Abenteuer",
    login: "Anmelden",
    logout: "Abmelden",
    preorder: "Jetzt vorbestellen",
    enterNow: "Jetzt einsteigen",
    features: {
      title: "Lernen war noch nie so spannend!",
      subtitle: "Unsere KI erschafft nicht nur spannende, sondern auch lehrreiche Geschichten, die sich perfekt an das Alter und die Interessen deines Kindes anpassen.",
      ageAppropriate: "Altersgerechtes Lernen",
      ageAppropriateDesc: "Inhalte werden automatisch an das Alter und Vorwissen deines Kindes angepasst.",
      topics: "Vielfältige Themen",
      topicsDesc: "Von Geschichte über Naturwissenschaften bis hin zu Alltagsthemen.",
      interactive: "Interaktives Erleben",
      interactiveDesc: "Kinder bestimmen selbst, wie sich ihre Geschichte entwickelt.",
      playful: "Spielerischer Ansatz",
      playfulDesc: "Lernen durch spannende Abenteuer und eigene Entscheidungen."
    },
    newsletter: {
      title: "Bleib auf dem Laufenden",
      subtitle: "Melde dich für unseren Newsletter an und erhalte Updates zu neuen Features, Geschichten-Themen und dem Verkaufsstart der Abenteuerkiste.",
      submit: "Anmelden",
      placeholder: "Deine E-Mail-Adresse",
      success: "Danke für deine Newsletter-Anmeldung! Wir halten dich auf dem Laufenden."
    },
    orderForm: {
      success: "Vielen Dank für deine Vorbestellung! Du erhältst in Kürze eine Bestätigungs-E-Mail.",
      error: "Es gab ein Problem mit deiner Vorbestellung. Bitte versuche es später erneut.",
      noCharge: "Es werden keine Kosten berechnet, bevor du nicht eine separate Bestätigungs-E-Mail mit allen Details erhältst.",
      buttonText: "Kostenfrei vorbestellen",
      processingText: "Vorbestellung wird verarbeitet..."
    },
    specs: {
      title: "Technische Spezifikationen",
      hardware: "Hardware",
      hardwareDesc: "Leistungsstarker Prozessor für schnelle Geschichtengenerierung",
      case: "Gehäuse",
      caseDesc: "Robustes, kindgerechtes Design",
      control: "Steuerung",
      controlDesc: "Intuitive Bedienung über Touchscreen",
      wireless: "Konnektivität",
      wirelessDesc: "WLAN für regelmäßige Updates",
      boxContents: "Lieferumfang",
      contents: [
        "Abenteuerkiste Gerät",
        "Netzteil",
        "Schnellstartanleitung",
        "Garantiekarte"
      ],
      shipping: "Versandkostenfrei"
    },
  
    stories: {
      title: "Beispiel-Abenteuer",
      subtitle: "Entdecke, welche spannenden und lehrreichen Geschichten die Abenteuerkiste erzählen kann",
      childLearns: "Das lernt dein Kind:"
    },
    footer: {
      description: "Die KI-gestützte Lern- und Geschichtenbox für Kinder",
      contact: "Kontakt",
      legal: "Rechtliches",
      privacy: "Datenschutz",
      imprint: "Impressum",
      terms: "AGB",
      rights: "Alle Rechte vorbehalten."
    },
      legal: {
        privacy: {
          title: "Datenschutzerklärung",
          responsible: "Verantwortlicher",
          dataProcessing: "Verarbeitung personenbezogener Daten",
          dataProcessingText: "Bei der Nutzung der Abenteuerkiste werden folgende personenbezogene Daten verarbeitet:",
          dataTypes: [
            "E-Mail-Adresse (für Newsletter und Vorbestellungen)",
            "Technische Nutzungsdaten (IP-Adresse, Browsertyp, etc.)"
          ],
          ai: "Künstliche Intelligenz",
          aiText: "Die Abenteuerkiste verwendet KI-Modelle zur Geschichtengenerierung. Wichtige Hinweise:",
          aiPoints: [
            "Es werden keine persönlichen Daten für das Training der KI verwendet",
            "Generierte Inhalte werden nicht gespeichert",
            "Die Antworten des KI-Modells werden moderiert, aber wir übernehmen keine Garantie für deren Inhalt"
          ],
          storage: "Datenspeicherung",
          storageText: "Wir speichern keine Gesprächsverläufe oder generierten Geschichten. Nur die für den Betrieb notwendigen technischen Daten werden temporär gespeichert.",
          rights: "Ihre Rechte",
          rightsText: "Sie haben das Recht auf:",
          rightsList: [
            "Auskunft über Ihre gespeicherten Daten",
            "Berichtigung unrichtiger Daten",
            "Löschung Ihrer Daten",
            "Widerruf erteilter Einwilligungen"
          ]
        },
        imprint: {
          title: "Impressum",
          companyDetails: "Angaben gemäß § 5 TMG",
          contact: "Kontakt",
          responsible: "Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV",
          aiNote: "Hinweis zur KI-Nutzung",
          aiNoteText: "Die Abenteuerkiste verwendet Künstliche Intelligenz zur Generierung von Geschichten. Die Inhalte werden moderiert, jedoch übernehmen wir keine Gewähr für die generierten Texte."
        },
        terms: {
          title: "Allgemeine Geschäftsbedingungen",
          scope: "Geltungsbereich",
          scopeText: "Diese AGB gelten für alle Bestellungen und Nutzungen der Abenteuerkiste zwischen WalibiWeare (Oliver Dzaeck) und den Kunden.",
          product: "Vertragsgegenstand",
          productText: "Die Abenteuerkiste ist ein KI-gestütztes Lernspielzeug, das interaktive Geschichten für Kinder generiert. Die Hardware wird von uns bereitgestellt, die Software wird regelmäßig aktualisiert.",
          preorder: "Vorbestellung",
          preorderText: "Mit der Vorbestellung bekunden Sie Ihr Interesse am Produkt. Die finale Zahlungsaufforderung erfolgt erst nach Bearbeitung Ihrer Vorbestellung.",
          aiContent: "KI-generierte Inhalte",
          aiPoints: [
            "Die Geschichten werden durch KI-Modelle generiert",
            "Wir moderieren die Inhalte, können aber keine Garantie für deren Qualität übernehmen",
            "Die Inhalte werden nicht gespeichert"
          ],
          withdrawal: "Widerrufsrecht",
          withdrawalText: "Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag der Bestellung.",
          liability: "Haftung",
          liabilityText: "Wir haften nicht für Schäden, die durch die Nutzung der KI-generierten Inhalte entstehen. Die Nutzung erfolgt auf eigene Gefahr."
        }
      }
    },
    en: {
      title: "Adventure Box",
      subtitle: "Discover & learn through exciting adventures",
      login: "Login",
      logout: "Logout",
      preorder: "Pre-order now",
      enterNow: "Enter now",
      features: {
        title: "Learning has never been more exciting!",
        subtitle: "Our AI creates not only exciting but also educational stories that perfectly adapt to your child's age and interests.",
        ageAppropriate: "Age-Appropriate Learning",
        ageAppropriateDesc: "Content automatically adapts to your child's age and previous knowledge.",
        topics: "Diverse Topics",
        topicsDesc: "From history to science to everyday topics.",
        interactive: "Interactive Experience",
        interactiveDesc: "Children determine how their story develops.",
        playful: "Playful Approach",
        playfulDesc: "Learning through exciting adventures and personal decisions."
      },
      specs: {
        title: "Technical Specifications",
        hardware: "Hardware",
        hardwareDesc: "Powerful processor for fast story generation",
        case: "Case",
        caseDesc: "Robust, child-friendly design",
        control: "Control",
        controlDesc: "Intuitive touch screen interface",
        wireless: "Connectivity",
        wirelessDesc: "WiFi for regular updates",
        boxContents: "Box Contents",
        contents: [
          "Adventure Box device",
          "Power adapter",
          "Quick start guide",
          "Warranty card"
        ],
        shipping: "Free shipping"
      },
      orderForm: {
        success: "Thank you for your pre-order! You will receive a confirmation email shortly.",
        error: "There was an issue with your pre-order. Please try again later.",
        noCharge: "No charges will be made before you receive a separate confirmation email with all details.",
        buttonText: "Pre-order for free",
        processingText: "Processing pre-order..."
      },
      newsletter: {
        title: "Stay Updated",
        subtitle: "Sign up for our newsletter and receive updates about new features, story themes, and the Adventure Box launch.",
        submit: "Subscribe",
        placeholder: "Your email address",
        success: "Thanks for subscribing! We'll keep you updated."
      },
      stories: {
        title: "Example Adventures",
        subtitle: "Discover what exciting and educational stories the Adventure Box can tell",
        childLearns: "Your child learns about:"
      },
      footer: {
        description: "The AI-powered learning and storytelling box for children",
        contact: "Contact",
        legal: "Legal",
        privacy: "Privacy Policy",
        imprint: "Legal Notice",
        terms: "Terms & Conditions",
        rights: "All rights reserved."
      },
      legal: {
        privacy: {
          title: "Privacy Policy",
          responsible: "Responsible Party",
          dataProcessing: "Processing of Personal Data",
          dataProcessingText: "When using the Adventure Box, the following personal data is processed:",
          dataTypes: [
            "Email address (for newsletter and pre-orders)",
            "Technical usage data (IP address, browser type, etc.)"
          ],
          ai: "Artificial Intelligence",
          aiText: "The Adventure Box uses AI models for story generation. Important notes:",
          aiPoints: [
            "No personal data is used for AI training",
            "Generated content is not stored",
            "The AI model's responses are moderated, but we do not guarantee their content"
          ],
          storage: "Data Storage",
          storageText: "We do not store any conversation histories or generated stories. Only technical data necessary for operation is temporarily stored.",
          rights: "Your Rights",
          rightsText: "You have the right to:",
          rightsList: [
            "Access your stored data",
            "Correct inaccurate data",
            "Delete your data",
            "Withdraw given consents"
          ]
        },
        imprint: {
          title: "Legal Notice",
          companyDetails: "Information according to § 5 TMG",
          contact: "Contact",
          responsible: "Responsible for content according to § 55 Abs. 2 RStV",
          aiNote: "AI Usage Notice",
          aiNoteText: "The Adventure Box uses artificial intelligence for story generation. The content is moderated, however, we do not guarantee the generated texts."
        },
        terms: {
          title: "Terms and Conditions",
          scope: "Scope",
          scopeText: "These terms and conditions apply to all orders and uses of the Adventure Box between WalibiWeare (Oliver Dzaeck) and customers.",
          product: "Subject Matter",
          productText: "The Adventure Box is an AI-powered educational toy that generates interactive stories for children. The hardware is provided by us, and the software is regularly updated.",
          preorder: "Pre-order",
          preorderText: "By pre-ordering, you express your interest in the product. The final payment request will only be sent after processing your pre-order.",
          aiContent: "AI-generated Content",
          aiPoints: [
            "Stories are generated by AI models",
            "We moderate the content but cannot guarantee its quality",
            "Content is not stored"
          ],
          withdrawal: "Right of Withdrawal",
          withdrawalText: "You have the right to withdraw from this contract within fourteen days without giving any reason. The withdrawal period is fourteen days from the day of ordering.",
          liability: "Liability",
          liabilityText: "We are not liable for damages caused by the use of AI-generated content. Use is at your own risk."
        }
      }
    }
  };
  


function LandingPage() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [showNewsletterAlert, setShowNewsletterAlert] = useState(false);
  const [showPreorderAlert, setShowPreorderAlert] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [email, setEmail] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [language, setLanguage] = useState('de');
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showImprintModal, setShowImprintModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  // const [orderForm, setOrderForm] = useState({
  //   email: ''
  // });

  // Translation helper
  const t = (key) => {
    const keys = key.split('.');
    return keys.reduce((obj, k) => obj?.[k], translations[language]) || key;
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      if (user) {
        navigate('/dashboard');
      }
    });
  
    return () => unsubscribe();
  }, [navigate, auth]);
  

  const handlePreorder = async (e) => {
    e.preventDefault();
    try {
      setShowProductModal(false);
      setShowPreorderAlert(true);
      setTimeout(() => setShowPreorderAlert(false), 5000); // Verlängerte Anzeigezeit auf 5 Sekunden
      setEmail('');
    } catch (error) {
      console.error('Preorder failed:', error);
      // Fehlerbehandlung wird jetzt im Modal durchgeführt
    }}

    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  // Neue Funktion für den API-Aufruf
const submitPreorder = async (email) => {
  try {
    if (!isValidEmail(email)) {
      alert('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
      return;
    }
    const API_URL = process.env.REACT_APP_PREORDER;
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error submitting preorder:', error);
    throw error;
  }
};

const submitNewsletter = async (email) => {
  try {
    if (!isValidEmail(email)) {
      alert('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
      return;
    }
    const REACT_APP_NEWSLETTER = process.env.REACT_APP_NEWSLETTER;
    const response = await fetch(REACT_APP_NEWSLETTER, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error submitting preorder:', error);
    throw error;
  }
};

  const handleNewsletter = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        await submitNewsletter(email);
        setShowNewsletterAlert(true);
        setEmail('');
        
        setTimeout(() => setShowNewsletterAlert(false), 3000);
      } catch (error) {
        console.error('Error submitting newsletter:', error);
      }
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  // Technical Specifications Component
  const TechnicalSpecs = () => (
    <div className="grid md:grid-cols-2 gap-4 mb-8">
      <div className="p-4 bg-white border border-amber-200 rounded-lg">
        <div className="flex items-center gap-3 mb-2">
          <Cpu className="w-6 h-6 text-amber-600" />
          <h4 className="font-semibold text-lg">{t('specs.hardware')}</h4>
        </div>
        <p className="text-amber-700">{t('specs.hardwareDesc')}</p>
      </div>
      <div className="p-4 bg-white border border-amber-200 rounded-lg">
        <div className="flex items-center gap-3 mb-2">
          <Box className="w-6 h-6 text-amber-600" />
          <h4 className="font-semibold text-lg">{t('specs.case')}</h4>
        </div>
        <p className="text-amber-700">{t('specs.caseDesc')}</p>
      </div>
      <div className="p-4 bg-white border border-amber-200 rounded-lg">
        <div className="flex items-center gap-3 mb-2">
          <Zap className="w-6 h-6 text-amber-600" />
          <h4 className="font-semibold text-lg">{t('specs.control')}</h4>
        </div>
        <p className="text-amber-700">{t('specs.controlDesc')}</p>
      </div>
      <div className="p-4 bg-white border border-amber-200 rounded-lg">
        <div className="flex items-center gap-3 mb-2">
          <Wifi className="w-6 h-6 text-amber-600" />
          <h4 className="font-semibold text-lg">{t('specs.wireless')}</h4>
        </div>
        <p className="text-amber-700">{t('specs.wirelessDesc')}</p>
      </div>
    </div>
  );
  const EmailInput = ({ value, onChange }) => {
    return (
      <div>
        <label htmlFor="email" className="block text-amber-700 mb-2 font-medium">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          value={value}
          onChange={onChange}
          required
          className="w-full px-4 py-3 border border-amber-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500 bg-white"
        />
      </div>
    );
  };
  
  const ProductModal = ({ onClose, onSubmit, email, onEmailChange, language, t }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setError(null);
      
      try {
        await submitPreorder(email);
        onSubmit(e);
      } catch (error) {
        setError(t('orderForm.error'));
      } finally {
        setIsSubmitting(false);
      }
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-start mb-6">
              <h2 className="text-3xl font-bold text-amber-900">{t('title')}</h2>
              <button
                onClick={onClose}
                className="p-1 hover:bg-amber-100 rounded-full"
              >
                <X className="w-6 h-6 text-amber-900" />
              </button>
            </div>
  
            <form onSubmit={handleSubmit} className="space-y-6">
              <EmailInput value={email} onChange={onEmailChange} />
  
              {error && (
                <div className="p-4 bg-red-100 text-red-700 rounded-lg">
                  {error}
                </div>
              )}
  
              <div className="bg-gradient-to-r from-amber-50 to-amber-100 p-6 rounded-lg shadow-sm">
                <h3 className="text-2xl font-bold text-amber-900 mb-4">Preis</h3>
                <div className="flex flex-col space-y-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-3xl font-bold text-amber-800">99,00 €</p>
                      <p className="text-amber-600 mt-1">{t('specs.shipping')}</p>
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="px-8 py-4 bg-amber-600 hover:bg-amber-500 disabled:bg-amber-300 text-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-200 inline-flex items-center gap-3 text-lg font-medium"
                    >
                      {isSubmitting ? (
                        <span>{t('orderForm.processingText')}</span>
                      ) : (
                        <>
                          <Send className="w-6 h-6" />
                          <span>{t('orderForm.buttonText')}</span>
                        </>
                      )}
                    </button>
                  </div>
                  <p className="text-amber-700 text-sm bg-amber-50 p-4 rounded-lg">
                    {t('orderForm.noCharge')}
                  </p>
                </div>
              </div>
            </form>
            
            {/* Rest des Modals bleibt gleich */}
          </div>
        </div>
      </div>
    );
  };
  
  const LegalModal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-start mb-6">
              <h2 className="text-3xl font-bold text-amber-900">{title}</h2>
              <button 
                onClick={onClose}
                className="p-1 hover:bg-amber-100 rounded-full"
              >
                <X className="w-6 h-6 text-amber-900" />
              </button>
            </div>
            <div className="overflow-y-auto prose max-w-none">
        <div className={language === 'de' ? 'block' : 'hidden'}>
          {/* Deutsche Version */}
          {children}
        </div>
        <div className={language === 'en' ? 'block' : 'hidden'}>
          {/* Englische Version */}
          {children}
        </div>
      </div>
          </div>
        </div>
      </div>
    );
  };

  const PrivacyModal = () => (
    <LegalModal 
      isOpen={showPrivacyModal} 
      onClose={() => setShowPrivacyModal(false)}
      language={language}
    >
      <h3>{t('legal.privacy.responsible')}</h3>
      <p>
        WalibiWeare<br />
        Oliver Dzaeck<br />
        Augustin-Wibbelt-Str. 34a<br />
        44534 Lünen<br />
        E-Mail: info@abenteuer-kiste.de
      </p>

      <h3>{t('legal.privacy.dataProcessing')}</h3>
      <p>{t('legal.privacy.dataProcessingText')}</p>
      <ul>
        {t('legal.privacy.dataTypes').map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <h3>{t('legal.privacy.ai')}</h3>
      <p>{t('legal.privacy.aiText')}</p>
      <ul>
        {t('legal.privacy.aiPoints').map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <h3>{t('legal.privacy.storage')}</h3>
      <p>{t('legal.privacy.storageText')}</p>

      <h3>{t('legal.privacy.rights')}</h3>
      <p>{t('legal.privacy.rightsText')}</p>
      <ul>
        {t('legal.privacy.rightsList').map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </LegalModal>
  );

  const ImprintModal = () => (
    <LegalModal 
      isOpen={showImprintModal} 
      onClose={() => setShowImprintModal(false)}
      title={t('legal.imprint.title')}
    >
      <h3>{t('legal.imprint.companyDetails')}</h3>
      <p>
        WalibiWeare<br />
        Oliver Dzaeck<br />
        Augustin-Wibbelt-Str. 34a<br />
        44534 Lünen
      </p>

      <h3>{t('legal.imprint.contact')}</h3>
      <p>
        E-Mail: info@abenteuer-kiste.de
      </p>

      <h3>{t('legal.imprint.responsible')}</h3>
      <p>
        Oliver Dzaeck<br />
        Augustin-Wibbelt-Str. 34a<br />
        44534 Lünen
      </p>

      <h3>{t('legal.imprint.aiNote')}</h3>
      <p>{t('legal.imprint.aiNoteText')}</p>
    </LegalModal>
  );

  const TermsModal = () => (
    <LegalModal 
      isOpen={showTermsModal} 
      onClose={() => setShowTermsModal(false)}
      title={t('legal.terms.title')}
    >
      <h3>1. {t('legal.terms.scope')}</h3>
      <p>{t('legal.terms.scopeText')}</p>

      <h3>2. {t('legal.terms.product')}</h3>
      <p>{t('legal.terms.productText')}</p>

      <h3>3. {t('legal.terms.preorder')}</h3>
      <p>{t('legal.terms.preorderText')}</p>

      <h3>4. {t('legal.terms.aiContent')}</h3>
      <ul>
        {t('legal.terms.aiPoints').map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <h3>5. {t('legal.terms.withdrawal')}</h3>
      <p>{t('legal.terms.withdrawalText')}</p>

      <h3>6. {t('legal.terms.liability')}</h3>
      <p>{t('legal.terms.liabilityText')}</p>
    </LegalModal>
  );
      
   
  const LanguageSelector = () => (
    <div className="flex items-center gap-2">
      <button
        onClick={() => setLanguage('de')}
        className={`flex items-center gap-2 px-3 py-1 rounded-lg ${
          language === 'de' ? 'bg-amber-800 text-white' : 'bg-amber-100 text-amber-900'
        }`}
      >
        <img
          src="/germany.png"
          alt="Deutsch"
          className="h-4 w-auto" // <-- Nur Höhe fixiert
        />
        DE
      </button>
  
      <button
        onClick={() => setLanguage('en')}
        className={`flex items-center gap-2 px-3 py-1 rounded-lg ${
          language === 'en' ? 'bg-amber-800 text-white' : 'bg-amber-100 text-amber-900'
        }`}
      >
        <img
          src="/united-states.png"
          alt="English"
          className="h-4 w-auto" // <-- Nur Höhe fixiert
        />
        EN
      </button>
    </div>
  );
  

  return (
    <div className="min-h-screen bg-amber-50 relative">
      {showNewsletterAlert && (
        <div className="fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg shadow-lg">
          {t('newsletter.success')}
        </div>
      )}
      {showPreorderAlert && (
        <div className="fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg shadow-lg">
          {t('orderForm.success')}
        </div>
      )}
    {showProductModal && (
        <ProductModal
          onClose={() => setShowProductModal(false)}          
          email={email}
          onEmailChange={handleEmailChange}
          onSubmit={handlePreorder}
          language={language}
          t={t}
        />
      )}

      {/* Header */}
      <header className="bg-gradient-to-r from-amber-700 to-amber-600 text-amber-50 shadow-lg">
  <div className="max-w-6xl mx-auto p-6">
    {/* Flex-Container für Logo/Titel und Buttons in einer Zeile */}
    <div className="flex items-center justify-between border-b border-amber-700/50 pb-4">
      {/* Linker Bereich: Logo und Titel */}
      <div className="flex items-center gap-3">
      <img
  src="/box.png"
  alt="Abenteuerkiste Logo"
  className="h-16 w-auto object-contain" // <-- noch größer als h-12
/>

        <div>
          <h1 className="text-4xl font-bold tracking-tight">{t('title')}</h1>
          <p className="text-amber-200 mt-1">{t('subtitle')}</p>
        </div>
      </div>
      
      {/* Rechter Bereich: LanguageSelector und Login/Logout-Button */}
      <div className="flex items-center gap-4">
        <LanguageSelector />
        {isAuthenticated ? (
          <button
            onClick={() => auth.signOut()}
            className="px-4 py-2 bg-amber-800 hover:bg-amber-700 text-white rounded-lg transition-colors duration-200"
          >
            {t('logout')}
          </button>
        ) : (
          <button
            onClick={handleLogin}
            className="px-4 py-2 bg-amber-800 hover:bg-amber-700 text-white rounded-lg transition-colors duration-200"
          >
            {t('login')}
          </button>
        )}
      </div>
    </div>
  </div>
</header>



      {/* Hero Section */}
      <section className="py-20 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={isAuthenticated ? () => navigate('/dashboard') : handleLogin}
              className="px-8 py-4 bg-amber-600 hover:bg-amber-500 text-white rounded-lg shadow-xl hover:shadow-2xl transition-all duration-200 transform hover:-translate-y-1 inline-flex items-center gap-3 font-semibold text-lg"
            >
              <span>{isAuthenticated ? t('enterNow') : t('login')}</span>
              <Book className="w-6 h-6" />
            </button>
            <button
              onClick={() => setShowProductModal(true)}
              className="px-8 py-4 bg-amber-800 hover:bg-amber-700 text-white rounded-lg shadow-xl hover:shadow-2xl transition-all duration-200 transform hover:-translate-y-1 inline-flex items-center gap-3 font-semibold text-lg"
            >
              <span>{t('preorder')}</span>
              <ShoppingBag className="w-6 h-6" />
            </button>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 px-4 bg-white">
        <div className="max-w-6xl mx-auto">
          <h3 className="text-3xl font-bold text-center text-amber-900 mb-4">{t('features.title')}</h3>
          <p className="text-center text-amber-700 text-lg mb-12">{t('features.subtitle')}</p>
          <div className="grid md:grid-cols-4 gap-8">
            <FeatureCard 
              icon={<Brain className="w-8 h-8" />} 
              title={t('features.ageAppropriate')} 
              description={t('features.ageAppropriateDesc')}
            />
            <FeatureCard 
              icon={<Globe className="w-8 h-8" />} 
              title={t('features.topics')} 
              description={t('features.topicsDesc')}
            />
            <FeatureCard 
              icon={<Sparkles className="w-8 h-8" />} 
              title={t('features.interactive')} 
              description={t('features.interactiveDesc')}
            />
            <FeatureCard 
              icon={<Heart className="w-8 h-8" />} 
              title={t('features.playful')} 
              description={t('features.playfulDesc')}
            />
          </div>
        </div>
      </section>

      {/* Story Examples Section */}
      <section className="py-20 px-4 bg-amber-100">
        <div className="max-w-6xl mx-auto">
          <h3 className="text-3xl font-bold text-center text-amber-900 mb-4">{t('stories.title')}</h3>
          <p className="text-center text-amber-700 text-lg mb-12">{t('stories.subtitle')}</p>
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            {storyExamples[language].map((story, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg border border-amber-200 hover:border-amber-400 transition-all duration-200">
                <div className="flex items-center gap-4 mb-4">
                  {story.icon}
                  <div>
                    <h4 className="text-xl font-bold text-amber-900">{story.title}</h4>
                    <p className="text-amber-600">{story.category}</p>
                  </div>
                </div>
                <p className="text-amber-700 mb-4">{story.preview}</p>
                <div className="space-y-2">
                  <p className="font-semibold text-amber-800">{t('stories.childLearns')}</p>
                  <ul className="list-disc list-inside text-amber-600">
                    {story.learningPoints.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Technical Specifications Section */}
      <section className="py-20 px-4 bg-white">
        <div className="max-w-6xl mx-auto">
          <h3 className="text-3xl font-bold text-center text-amber-900 mb-12">{t('specs.title')}</h3>
          <TechnicalSpecs />
        </div>
      </section>

      {/* Newsletter Section */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-4xl mx-auto text-center">
          <h3 className="text-3xl font-bold text-amber-900 mb-6">{t('newsletter.title')}</h3>
          <p className="text-lg text-amber-700 mb-8">{t('newsletter.subtitle')}</p>
          <form onSubmit={handleNewsletter} className="max-w-md mx-auto">
            <div className="flex flex-col sm:flex-row gap-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('newsletter.placeholder')}
                className="flex-1 px-4 py-3 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
                required
              />
              <button 
                type="submit" 
                className="px-6 py-3 bg-amber-600 hover:bg-amber-500 text-white rounded-lg shadow-lg transition-all duration-200 inline-flex items-center gap-2"
              >
                <Mail className="w-5 h-5" />
                <span>{t('newsletter.submit')}</span>
              </button>
            </div>
          </form>
        </div>
      </section>

      {showTermsModal && <TermsModal />}
      {showPrivacyModal && <PrivacyModal />}
      {showImprintModal && <ImprintModal />}

      {/* Footer */}
      <footer className="bg-amber-900 text-amber-100 py-12 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-3 gap-8 mb-8">
            <div>
              <h4 className="font-bold text-xl mb-4">{t('title')}</h4>
              <p className="text-amber-300">{t('footer.description')}</p>
            </div>
            <div>
              <h4 className="font-bold text-xl mb-4">{t('footer.contact')}</h4>
              <p className="text-amber-300">info@abenteuer-kiste.de</p>
            </div>
            <div>
              <h4 className="font-bold text-xl mb-4">{t('footer.legal')}</h4>
              <ul className="text-amber-300 space-y-2">
                <li>
                  <button 
                    onClick={() => setShowPrivacyModal(true)}
                    className="hover:text-amber-200 transition-colors"
                  >
                    {t('footer.privacy')}
                  </button>
                </li>
                <li>
                  <button 
                    onClick={() => setShowImprintModal(true)}
                    className="hover:text-amber-200 transition-colors"
                  >
                    {t('footer.imprint')}
                  </button>
                </li>
                <li>
                  <button 
                    onClick={() => setShowTermsModal(true)}
                    className="hover:text-amber-200 transition-colors"
                  >
                    {t('footer.terms')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="text-center pt-8 border-t border-amber-800">
            <p>© 2024 {t('title')}. {t('footer.rights')}</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <div className="p-6 rounded-xl bg-amber-50 hover:shadow-xl transition-shadow duration-200 border border-amber-200 hover:border-amber-300">
      <div className="text-amber-600 mb-4 flex justify-center">
        {icon}
      </div>
      <h3 className="text-xl font-bold text-amber-900 mb-3">{title}</h3>
      <p className="text-amber-700 leading-relaxed">{description}</p>
    </div>
  );
}

export default LandingPage;