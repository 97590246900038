import React, { useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { Power, Wifi, Globe, Lock, Check, PenTool, ShoppingBag, PlayCircle, AlertCircle } from 'lucide-react';

const Setup = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const setupSteps = [
    {
      title: "Abenteuerkiste einschalten",
      icon: <Power className="w-8 h-8 text-amber-600" />,
      description: "Schalte deine Abenteuerkiste mit dem Power-Knopf ein. Warte bis die Status-LED blau leuchtet.",
      additionalInfo: "Die Box startet automatisch einen eigenen WLAN-Access-Point namens 'Abenteuerkiste'.",
      tips: [
        "Der Startvorgang dauert etwa 30 Sekunden",
        "Die blaue LED zeigt die Betriebsbereitschaft an",
        "Stelle sicher, dass die Box an eine Stromquelle angeschlossen ist"
      ]
    },
    {
      title: "Mit WLAN verbinden",
      icon: <Wifi className="w-8 h-8 text-amber-600" />,
      description: "Verbinde dein Smartphone oder Tablet mit dem WLAN 'Abenteuerkiste'.",
      additionalInfo: "Öffne die WLAN-Einstellungen deines Geräts und wähle das Netzwerk 'Abenteuerkiste' aus.",
      tips: [
        "Das WLAN erscheint automatisch in der Liste verfügbarer Netzwerke",
        "Falls das WLAN nicht erscheint, stelle sicher, dass du dich in Reichweite befindest",
        "Das Standard-Passwort findest du auf der Unterseite deiner Abenteuerkiste"
      ]
    },
    {
      title: "Weboberfläche aufrufen",
      icon: <Globe className="w-8 h-8 text-amber-600" />,
      description: "Öffne deinen Browser und rufe 'abenteuerkiste.local' auf.",
      additionalInfo: "Die Einrichtungsseite sollte sich automatisch öffnen. Falls nicht, gib die Adresse manuell ein.",
      tips: [
        "Funktioniert mit allen gängigen Browsern",
        "Falls die Seite nicht lädt, versuche 'http://192.168.4.1'",
        "Stelle sicher, dass du mit dem richtigen WLAN verbunden bist"
      ]
    },
    {
      title: "Box mit deinem WLAN verbinden",
      icon: <Lock className="w-8 h-8 text-amber-600" />,
      description: "Wähle dein heimisches WLAN aus und gib das Passwort ein.",
      additionalInfo: "Die Box zeigt eine Liste aller verfügbaren WLAN-Netzwerke an. Wähle deins aus und verbinde die Box.",
      tips: [
        "Verwende ein 2.4 GHz Netzwerk für beste Reichweite",
        "Das Passwort wird sicher verschlüsselt gespeichert",
        "Die Box merkt sich die Einstellungen auch nach einem Neustart"
      ]
    },
    {
      title: "Anmeldung",
      icon: <PenTool className="w-8 h-8 text-amber-600" />,
      description: "Melde dich mit deinen Zugangsdaten an der Abenteuerkiste an.",
      additionalInfo: "Nutze die gleichen Anmeldedaten wie auf der Website.",
      tips: [
        "Deine E-Mail-Adresse ist dein Benutzername",
        "Passwort vergessen? Nutze die Zurücksetzen-Funktion",
        "Die Anmeldung bleibt für 30 Tage aktiv"
      ]
    }
  ];

  return (
    <DashboardLayout>
      <div className="max-w-4xl mx-auto">
        <div className="mb-8">
          <h2 className="text-3xl font-bold text-amber-900">Einrichtung deiner Abenteuerkiste</h2>
          <p className="text-amber-700 mt-2">
            Folge dieser Anleitung, um deine Abenteuerkiste einzurichten und mit deinem WLAN zu verbinden.
          </p>
        </div>

        {/* Setup Steps */}
        <div className="space-y-8">
          {setupSteps.map((step, index) => (
            <div
              key={index}
              className={`bg-white rounded-xl shadow-lg border ${
                currentStep === index + 1 ? 'border-amber-400' : 'border-amber-200'
              } p-6`}
            >
              <div className="flex items-start gap-6">
                <div className="flex-shrink-0 w-12 h-12 bg-amber-50 rounded-full flex items-center justify-center">
                  {step.icon}
                </div>
                <div className="flex-grow">
                  <div className="flex items-center gap-3 mb-3">
                    <span className="bg-amber-100 text-amber-800 px-3 py-1 rounded-full text-sm font-medium">
                      Schritt {index + 1}
                    </span>
                    <h3 className="text-xl font-bold text-amber-900">{step.title}</h3>
                  </div>
                  <p className="text-amber-700 mb-4">{step.description}</p>
                  <p className="text-amber-600 italic mb-4">{step.additionalInfo}</p>
                  
                  <div className="bg-amber-50 rounded-lg p-4">
                    <h4 className="font-semibold text-amber-900 mb-2">Tipps:</h4>
                    <ul className="space-y-2">
                      {step.tips.map((tip, tipIndex) => (
                        <li key={tipIndex} className="flex items-start gap-2">
                          <Check className="w-5 h-5 text-amber-600 flex-shrink-0 mt-0.5" />
                          <span className="text-amber-700">{tip}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Subscription Check */}
        <div className="mt-8 bg-amber-50 rounded-xl p-6 border border-amber-300">
          <div className="flex items-start gap-4">
            <AlertCircle className="w-6 h-6 text-amber-600 flex-shrink-0" />
            <div>
              <h3 className="text-lg font-semibold text-amber-900 mb-2">Abo-Status prüfen</h3>
              <p className="text-amber-700 mb-4">
                Um die Abenteuerkiste nutzen zu können, benötigst du ein aktives Abonnement. 
                Prüfe deinen Abo-Status oder schließe ein neues Abo ab.
              </p>
              <div className="flex gap-4">
                <button className="px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200 flex items-center gap-2">
                  <ShoppingBag className="w-5 h-5" />
                  <span>Abo abschließen</span>
                </button>
                <button className="px-4 py-2 bg-amber-800 text-white rounded-lg hover:bg-amber-700 transition-colors duration-200 flex items-center gap-2">
                  <PlayCircle className="w-5 h-5" />
                  <span>Abo-Status prüfen</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Setup;