import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Import der Seiten
import LandingPage from '../src/pages/LandingPage';
import Dashboard from '../src/pages/Dashboard';
import AccountSettings from '../src/pages/AccountSettings';
import BoxSettings from '../src/pages/BoxSettings';
import Login from '../src/pages/Login';
import Subscription from '../src/pages/Subscription';
import FAQ from '../src/pages/FAQ';
import Setup from '../src/pages/Setup';
import PrivateRoute from './components/PrivateRoute';

// Firebase Konfiguration
const firebaseConfig = {
  apiKey: "AIzaSyAsFKzPi3W-_qSMwfHUO3aZ0peFI2pcL50",
  authDomain: "abenteuerkiste-18cd3.firebaseapp.com",
  projectId: "abenteuerkiste-18cd3",
  storageBucket: "abenteuerkiste-18cd3.firebasestorage.app",
  messagingSenderId: "1074743744532",
  appId: "1:1074743744532:web:8c0da14e0a6069cd72ef01",
  measurementId: "G-1ZHCGPX8RK"
};
// Firebase initialisieren
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

function App() {
  return (
    <Router>
      <Routes>
        {/* Öffentliche Routen */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        
        {/* Geschützte Routen */}
        <Route path="/dashboard" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        } />
        <Route path="/account" element={
          <PrivateRoute>
            <AccountSettings />
          </PrivateRoute>
        } />        
        <Route path="/subscription" element={
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>
        } />
        <Route path="/faq" element={
          <PrivateRoute>
            <FAQ />
          </PrivateRoute>
        } />
        <Route path="/setup" element={
          <PrivateRoute>
            <Setup />
          </PrivateRoute>
        } />
      </Routes>
    </Router>
  );
}

export default App;