import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import { Eye, EyeOff, ArrowLeft } from 'lucide-react';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const getErrorMessage = (errorCode) => {
    const errorMessages = {
      'auth/invalid-email': 'Die E-Mail-Adresse ist ungültig.',
      'auth/user-disabled': 'Dieser Account wurde deaktiviert.',
      'auth/user-not-found': 'Kein Account mit dieser E-Mail-Adresse gefunden.',
      'auth/wrong-password': 'Falsches Passwort.',
      'auth/email-already-in-use': 'Diese E-Mail-Adresse wird bereits verwendet.',
      'auth/weak-password': 'Das Passwort muss mindestens 6 Zeichen lang sein.',
      'auth/missing-password': 'Bitte gib ein Passwort ein.',
      'auth/too-many-requests': 'Zu viele Versuche. Bitte versuche es später erneut.',
      'auth/network-request-failed': 'Netzwerkfehler. Bitte überprüfe deine Internetverbindung.',
      'auth/invalid-credential': 'Ungültige Anmeldedaten. Bitte überprüfe deine E-Mail und dein Passwort.',
      'auth/operation-not-allowed': 'Die Anmeldung ist derzeit nicht möglich.',
      'auth/popup-closed-by-user': 'Der Anmeldevorgang wurde abgebrochen.',
      'auth/cancelled-popup-request': 'Der Anmeldevorgang wurde abgebrochen.',
      'auth/popup-blocked': 'Das Popup wurde blockiert. Bitte erlaube Popups für diese Seite.',
      'auth/internal-error': 'Ein interner Fehler ist aufgetreten. Bitte versuche es erneut.'
    };
    return errorMessages[errorCode] || `Ein Fehler ist aufgetreten (${errorCode}). Bitte versuche es erneut.`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    // Einfache Validierung
    if (!email.trim()) {
      setError('Bitte gib eine E-Mail-Adresse ein.');
      setLoading(false);
      return;
    }

    if (!isResettingPassword && !password.trim()) {
      setError('Bitte gib ein Passwort ein.');
      setLoading(false);
      return;
    }

    try {
      if (isResettingPassword) {
        await sendPasswordResetEmail(auth, email);
        setSuccess('Eine E-Mail zum Zurücksetzen des Passworts wurde verschickt.');
        setIsResettingPassword(false);
      } else if (isRegistering) {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate('/dashboard');
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Auth Error:', error);
      setError(getErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setIsResettingPassword(false);
    setError('');
    setSuccess('');
  };

  return (
    <div className="min-h-screen bg-amber-50 flex flex-col">
           {/* Header */}
           <header className="bg-gradient-to-r from-amber-700 to-amber-600 text-amber-50 shadow-lg">
  <div className="max-w-6xl mx-auto p-6">
    {/* Flex-Container für Logo/Titel und Buttons in einer Zeile */}
    <div className="flex items-center justify-between border-b border-amber-700/50 pb-4">
      {/* Linker Bereich: Logo und Titel */}
      <div className="flex items-center gap-3">
      <img
  src="/box.png"
  alt="Abenteuerkiste Logo"
  className="h-16 w-auto object-contain" // <-- noch größer als h-12
/>

        <div>
          <h1 className="text-4xl font-bold tracking-tight">{"Abenteuerkiste"}</h1>
          <p className="text-amber-200 mt-1">{"Entdecke & lerne durch spannende Abenteuer"}</p>
        </div>
      </div>
      
      {/* Rechter Bereich: LanguageSelector und Login/Logout-Button */}
   
    </div>
  </div>
</header>


      <main className="flex-grow flex items-center justify-center p-4">
        <div className="max-w-md w-full">
          <div className="bg-white rounded-xl shadow-lg p-8">
            {isResettingPassword && (
              <button
                onClick={handleBack}
                className="mb-4 text-amber-600 hover:text-amber-700 flex items-center gap-2"
              >
                <ArrowLeft className="w-4 h-4" />
                Zurück zur Anmeldung
              </button>
            )}

            <h2 className="text-2xl font-semibold text-amber-900 text-center mb-6">
              {isResettingPassword 
                ? 'Passwort zurücksetzen'
                : (isRegistering ? 'Account erstellen' : 'Anmelden')}
            </h2>

            {error && (
              <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg">
                {error}
              </div>
            )}

            {success && (
              <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-lg">
                {success}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  E-Mail
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 
                           focus:ring-amber-500 focus:border-transparent"
                  required
                />
              </div>

              {!isResettingPassword && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Passwort
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 
                               focus:ring-amber-500 focus:border-transparent pr-10"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
                    >
                      {showPassword ? 
                        <EyeOff className="h-5 w-5" /> : 
                        <Eye className="h-5 w-5" />
                      }
                    </button>
                  </div>
                </div>
              )}

              <button
                type="submit"
                disabled={loading}
                className="w-full py-2 px-4 bg-amber-600 hover:bg-amber-700 text-white rounded-lg
                         transition-colors duration-200 disabled:opacity-50"
              >
                {loading 
                  ? 'Wird verarbeitet...' 
                  : (isResettingPassword 
                      ? 'Passwort zurücksetzen'
                      : (isRegistering ? 'Registrieren' : 'Anmelden'))
                }
              </button>
            </form>

            <div className="mt-4 text-center space-y-2">
              {!isResettingPassword && (
                <button
                  onClick={() => setIsRegistering(!isRegistering)}
                  className="text-amber-600 hover:text-amber-700 block w-full"
                >
                  {isRegistering 
                    ? 'Bereits registriert? Zur Anmeldung' 
                    : 'Noch kein Account? Jetzt registrieren'
                  }
                </button>
              )}
              
              {!isResettingPassword && !isRegistering && (
                <button
                  onClick={() => {
                    setIsResettingPassword(true);
                    setError('');
                    setSuccess('');
                  }}
                  className="text-amber-600 hover:text-amber-700 block w-full"
                >
                  Passwort vergessen?
                </button>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;