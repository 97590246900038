import React, { useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { ChevronDown, ChevronUp, Search, Box, Wifi, CreditCard, Brain, Settings, Shield, HelpCircle } from 'lucide-react';

const FAQ = () => {
  const [openCategory, setOpenCategory] = useState('general');
  const [searchTerm, setSearchTerm] = useState('');

  const faqCategories = {
    general: {
      icon: <Box className="w-6 h-6" />,
      title: "Allgemeine Fragen",
      questions: [
        {
          q: "Was ist die Abenteuerkiste?",
          a: "Die Abenteuerkiste ist ein interaktiver KI-gestützter Geschichtenerzähler für Kinder. Sie verbindet modernste Technologie mit kreativen Geschichten, um Kindern ein personalisiertes Lernerlebnis zu bieten."
        },
        {
          q: "Für welches Alter ist die Abenteuerkiste geeignet?",
          a: "Die Abenteuerkiste ist für Kinder von 2-12 Jahren geeignet. Die Inhalte werden automatisch an das Alter und den Entwicklungsstand deines Kindes angepasst."
        },
        {
          q: "Wie lange dauert eine durchschnittliche Geschichte?",
          a: "Die Geschichtenlänge ist anpassbar und variiert je nach Altersgruppe: von 2-3 Minuten für Kleinkinder bis zu 15-20 Minuten für ältere Kinder."
        }
      ]
    },
    technical: {
      icon: <Wifi className="w-6 h-6" />,
      title: "Technische Fragen",
      questions: [
        {
          q: "Wie verbinde ich die Abenteuerkiste mit meinem WLAN?",
          a: "1. Schalte die Box ein\n2. Verbinde dich mit dem WLAN 'Abenteuerkiste'\n3. Öffne 'abenteuerkiste.local' im Browser\n4. Folge den Einrichtungsschritten"
        },
        {
          q: "Was mache ich, wenn die Box nicht mit dem WLAN verbindet?",
          a: "Überprüfe, ob:\n- dein WLAN-Passwort korrekt ist\n- du ein 2.4 GHz Netzwerk verwendest\n- die Box in Reichweite ist\n- ein Reset der Box notwendig ist"
        },
        {
          q: "Wie führe ich ein Software-Update durch?",
          a: "Updates werden automatisch installiert, wenn die Box mit dem Internet verbunden ist. Ein manuelles Update kann über die Einstellungen durchgeführt werden."
        }
      ]
    },
    subscription: {
      icon: <CreditCard className="w-6 h-6" />,
      title: "Abo & Bezahlung",
      questions: [
        {
          q: "Welche Abo-Modelle gibt es?",
          a: "Wir bieten verschiedene Abo-Modelle an:\n- Monatsabo: 9,99€/Monat\n- Jahresabo: 99€/Jahr (spare 20%)\n- Familienabo: 14,99€/Monat für bis zu 3 Kinder"
        },
        {
          q: "Kann ich mein Abo kündigen?",
          a: "Ja, du kannst dein Abo jederzeit zum Ende der Laufzeit kündigen. Die Kündigung kann einfach in den Kontoeinstellungen vorgenommen werden."
        },
        {
          q: "Gibt es eine Mindestlaufzeit?",
          a: "Nein, es gibt keine Mindestlaufzeit. Du kannst monatlich kündigen."
        }
      ]
    },
    content: {
      icon: <Brain className="w-6 h-6" />,
      title: "Inhalte & Geschichten",
      questions: [
        {
          q: "Wie werden die Geschichten erstellt?",
          a: "Unsere KI erstellt personalisierte Geschichten basierend auf dem Alter, den Interessen und dem Lernfortschritt deines Kindes. Alle Inhalte werden pädagogisch geprüft."
        },
        {
          q: "Welche Themen werden abgedeckt?",
          a: "Die Geschichten decken ein breites Spektrum ab:\n- Naturwissenschaften\n- Geschichte\n- Soziale Themen\n- Alltagssituationen\n- Fantasie und Abenteuer"
        },
        {
          q: "Sind die Inhalte kindgerecht?",
          a: "Ja, alle Inhalte werden automatisch und von unserem pädagogischen Team auf Altersangemessenheit geprüft."
        }
      ]
    },
    settings: {
      icon: <Settings className="w-6 h-6" />,
      title: "Einstellungen & Anpassungen",
      questions: [
        {
          q: "Wie kann ich die Schwierigkeit anpassen?",
          a: "Die Schwierigkeit wird automatisch angepasst, kann aber in den Einstellungen manuell für jedes Kind festgelegt werden."
        },
        {
          q: "Kann ich mehrere Kinderprofile erstellen?",
          a: "Ja, mit einem Familienabo können bis zu 3 Kinderprofile mit individuellen Einstellungen erstellt werden."
        }
      ]
    },
    privacy: {
      icon: <Shield className="w-6 h-6" />,
      title: "Datenschutz & Sicherheit",
      questions: [
        {
          q: "Wie werden die Daten meines Kindes geschützt?",
          a: "Wir nehmen den Schutz der Daten sehr ernst. Alle Daten werden verschlüsselt gespeichert und nicht an Dritte weitergegeben."
        },
        {
          q: "Werden Nutzungsdaten gesammelt?",
          a: "Wir sammeln nur die notwendigsten Daten, um das Lernerlebnis zu personalisieren. Die Datenerhebung kann in den Einstellungen angepasst werden."
        }
      ]
    }
  };

  const filteredCategories = Object.entries(faqCategories).map(([key, category]) => ({
    ...category,
    key,
    questions: category.questions.filter(q => 
      q.q.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.a.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })).filter(category => category.questions.length > 0);

  return (
    <DashboardLayout>
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-3xl font-bold text-amber-900">Häufig gestellte Fragen</h2>
          <div className="flex items-center gap-2 bg-white rounded-lg border border-amber-200 px-4 py-2">
            <Search className="w-5 h-5 text-amber-600" />
            <input
              type="text"
              placeholder="Fragen durchsuchen..."
              className="border-none focus:outline-none text-amber-900 placeholder-amber-400"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-6">
          {filteredCategories.map(({ key, icon, title, questions }) => (
            <div key={key} className="bg-white rounded-xl shadow-lg border border-amber-200">
              <button
                className="w-full px-6 py-4 flex items-center justify-between"
                onClick={() => setOpenCategory(openCategory === key ? null : key)}
              >
                <div className="flex items-center gap-3">
                  <div className="text-amber-600">
                    {icon}
                  </div>
                  <h3 className="text-xl font-bold text-amber-900">{title}</h3>
                </div>
                {openCategory === key ? 
                  <ChevronUp className="w-5 h-5 text-amber-600" /> : 
                  <ChevronDown className="w-5 h-5 text-amber-600" />
                }
              </button>

              {openCategory === key && (
                <div className="px-6 pb-6">
                  <div className="space-y-6">
                    {questions.map((faq, index) => (
                      <div key={index} className="border-b border-amber-100 last:border-0 pb-6 last:pb-0">
                        <h4 className="text-lg font-semibold text-amber-900 mb-2">{faq.q}</h4>
                        <p className="text-amber-700 whitespace-pre-line">{faq.a}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Kontakt-Support */}
        <div className="mt-8 bg-amber-50 rounded-xl p-6 border border-amber-200">
          <div className="flex items-start gap-4">
            <HelpCircle className="w-6 h-6 text-amber-600 flex-shrink-0" />
            <div>
              <h3 className="text-lg font-semibold text-amber-900 mb-2">Keine Antwort gefunden?</h3>
              <p className="text-amber-700 mb-4">
                Unser Support-Team hilft dir gerne bei weiteren Fragen.
              </p>
              <button className="px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200">
                Support kontaktieren
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default FAQ;