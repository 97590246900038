import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import DashboardLayout from '../components/DashboardLayout';
import { 
  Book, 
  Brain, 
  Clock, 
  Star, 
  Settings, 
  Shield, 
  Wrench, 
  ArrowRight, 
  AlertCircle,
  Box,
  Trash2,
  ChevronDown,
  ChevronUp
} from 'lucide-react';

const SubscriptionUsage = ({ subscriptionInfo, navigate }) => {
  // Format the date to German format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  // Berechnung des prozentualen Fortschritts
  const progressPercentage = subscriptionInfo.totalMinutesPerPeriod > 0
    ? (subscriptionInfo.usedMinutesInPeriod / subscriptionInfo.totalMinutesPerPeriod) * 100
    : 0;

  return (
    <>
      <div className="mb-4">
        <div className="w-full h-4 bg-amber-100 rounded-full overflow-hidden">
          <div
            className="h-full bg-amber-600 transition-all duration-500"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
        <div className="flex justify-between mt-2 text-sm text-amber-700">
          <span>{subscriptionInfo.usedMinutesInPeriod} Minuten verbraucht</span>
          <span>{subscriptionInfo.remainingMinutes} Minuten verfügbar</span>
        </div>
      </div>

      <div className="bg-amber-50 rounded-lg p-4 mb-4">
        <div className="space-y-4">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-amber-900 font-semibold text-lg">
                {subscriptionInfo.subscription.name}
              </p>
              <div className="space-y-1 mt-2">
                <p className="text-amber-700">
                  {subscriptionInfo.totalMinutesPerPeriod} Minuten pro Monat
                </p>
                <p className="text-amber-700 text-sm">
                  Status: {subscriptionInfo.subscription.isActive ? 
                    <span className="text-green-600 font-medium">Aktiv</span> : 
                    <span className="text-red-600 font-medium">Inaktiv</span>
                  }
                </p>
                <p className="text-amber-700 text-sm">
                  Verlängert sich am: {formatDate(subscriptionInfo.subscription.endDate)}
                </p>
              </div>
            </div>
            <button
              onClick={() => navigate('/subscription')}
              className="px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200"
            >
              Abo verwalten
            </button>
          </div>
          <div className="text-sm text-amber-600 bg-amber-100/50 rounded-lg p-3">
            <p>Dein Premium-Abo verlängert sich automatisch am {formatDate(subscriptionInfo.subscription.endDate)}. Du kannst dein Abo jederzeit in den Einstellungen anpassen.</p>
          </div>
        </div>
      </div>

      {/* Warnung bei wenigen verbleibenden Minuten */}
      {subscriptionInfo.remainingMinutes < 60 && (
        <div className="flex items-start gap-3 bg-amber-50 rounded-lg p-4 text-amber-800">
          <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
          <p>Deine Geschichtenminuten gehen zur Neige. Upgrade jetzt dein Abo für mehr Geschichtenspaß!</p>
        </div>
      )}
    </>
  );
};

const ConfirmationModal = ({ isOpen, onClose, onConfirm, isLoading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6 relative">
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            Abentuerkiste entfernen?
          </h2>
          <p className="text-gray-600">
            Möchtest du die Verbindung zu dieser Abentuerkiste wirklich aufheben? 
            Eine erneute Verbindung ist nur direkt über die Abenteuerkiste möglich.
          </p>
        </div>
        
        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            disabled={isLoading}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors duration-200 disabled:opacity-50"
          >
            Abbrechen
          </button>
          <button
            onClick={onConfirm}
            disabled={isLoading}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200 disabled:opacity-50 flex items-center gap-2"
          >
            {isLoading ? 'Wird entfernt...' : 'Ja, entfernen'}
          </button>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [complexity, setComplexity] = useState(1);
  const [storyLength, setStoryLength] = useState('short');
  const [devices, setDevices] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('young-children');
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const [expandedSection, setExpandedSection] = useState('devices');

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const ageGroups = {
    'toddlers': {
      label: 'Kleinkinder (2-3 Jahre)',
      description: 'Einfache, kurze Geschichten mit vielen Wiederholungen',
      settings: {
        vocabulary: 'Sehr einfach',
        complexity: 'Minimal',
        length: '2-3 Minuten',
        themes: ['Tiere', 'Familie', 'Alltag'],
        details: {
          narrative: 'Einfache, lineare Erzählungen mit klaren Wiederholungsmustern',
          characters: 'Wenige, klar unterscheidbare Charaktere',
          educational: 'Fokus auf Grundbegriffe, Farben, Zahlen und alltägliche Gegenstände',
          interaction: 'Häufige Pausen für Reaktionen und Nachahmungen'
        }
      }
    },
    'young-children': {
      label: 'Vorschulkinder (4-5 Jahre)',
      description: 'Bildhafte Geschichten mit klaren Handlungen',
      settings: {
        vocabulary: 'Einfach',
        complexity: 'Gering',
        length: '5-7 Minuten',
        themes: ['Freundschaft', 'Abenteuer', 'Natur', 'Fantasie'],
        details: {
          narrative: 'Klare Handlungsbögen mit einfachen Spannungselementen',
          characters: 'Vielfältigere Charaktere mit deutlichen Persönlichkeiten',
          educational: 'Integration von Sozialkompetenzen und erstem Weltwissen',
          interaction: 'Einbindung von Fragen und einfachen Entscheidungen'
        }
      }
    },
    'school-children': {
      label: 'Grundschulkinder (6-9 Jahre)',
      description: 'Spannende Geschichten mit Lerneffekt',
      settings: {
        vocabulary: 'Altersgerecht',
        complexity: 'Mittel',
        length: '10-15 Minuten',
        themes: ['Wissenschaft', 'Geschichte', 'Weltraum', 'Technik'],
        details: {
          narrative: 'Komplexere Handlungen mit mehreren Handlungssträngen',
          characters: 'Vielschichtige Charaktere mit Entwicklung',
          educational: 'Integration von Schulwissen und wissenschaftlichen Konzepten',
          interaction: 'Rätsel und Problemlösungsaufgaben'
        }
      }
    },
    'older-children': {
      label: 'Ältere Kinder (10-12 Jahre)',
      description: 'Komplexere Geschichten mit Wissensvertiefung',
      settings: {
        vocabulary: 'Erweitert',
        complexity: 'Hoch',
        length: '15-20 Minuten',
        themes: ['Naturwissenschaften', 'Weltgeschichte', 'Technologie', 'Kultur'],
        details: {
          narrative: 'Anspruchsvolle Geschichten mit verschachtelten Handlungen',
          characters: 'Komplexe Charakterentwicklung und Beziehungen',
          educational: 'Tiefgehende Wissensvermittlung und kritisches Denken',
          interaction: 'Komplexe Entscheidungen und Diskussionsanregungen'
        }
      }
    }
  };

  const fetchSubscriptionDetails = async () => {
    try {
      setSubscriptionLoading(true);
      const user = auth.currentUser;
      if (!user) return;

      const idToken = await user.getIdToken();
      const response = await fetch('https://abenteuerkiste-apim-01.azure-api.net/subscription/api/Subscription/details', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setSubscriptionInfo(data);
      } else {
        console.error('Failed to fetch subscription details');
      }
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    } finally {
      setSubscriptionLoading(false);
    }
  };

  const fetchAgeSettings = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;
      const APIURL = "https://abenteuerkiste-apim-01.azure-api.net/user/api/UserInfo/age-settings"
      const idToken = await user.getIdToken();
      const response = await fetch(
        APIURL,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${idToken}`,
          },
        }
      );

      if (response.ok) {
        const ageResponse = await response.json();
        console.log('Current age category:', ageResponse);
        setSelectedAgeGroup(ageResponse.ageCategory);
      } else {
        console.error('Failed to fetch age settings');
      }
    } catch (error) {
      console.error('Error fetching age settings:', error);
    }
  };

  const fetchDevices = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const idToken = await user.getIdToken();
      const response = await fetch(
        'https://abenteuerkiste-apim-01.azure-api.net/user/api/UserDevice/list-devices',
        {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDevices(data.adventureChests || []);
      } else {
        console.error('Failed to fetch devices');
        setDevices([]);
      }
    } catch (error) {
      console.error('Error fetching devices:', error);
      setDevices([]);
    }
  };

  const handleAgeGroupChange = (groupKey) => {
    if (ageGroups[groupKey]) {
      setSelectedAgeGroup(groupKey);
    } else {
      console.error(`Invalid age group selected: ${groupKey}`);
    }
  };

  const updateAgeSettings = async (ageCategory) => {
    try {
      const user = auth.currentUser;
      if (!user) return;
  
      const idToken = await user.getIdToken();
      const response = await fetch(
        'https://abenteuerkiste-apim-01.azure-api.net/user/api/UserInfo/age-settings',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify(ageCategory),
        }
      );
  
      if (response.ok) {
        console.log('Age settings updated successfully');
      } else {
        console.error('Failed to update age settings');
      }
    } catch (error) {
      console.error('Error updating age settings:', error);
    }
  };

  const handleUnlinkDevice = async () => {
    if (!selectedDevice) return;

    setIsLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) return;

      const idToken = await user.getIdToken();
      const response = await fetch(
        'https://abenteuerkiste-apim-01.azure-api.net/user/api/UserDevice/unlink-device',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            deviceId: selectedDevice.deviceId
          })
        }
      );

      if (response.ok) {
        await fetchDevices();
        setIsConfirmOpen(false);
      } else {
        console.error('Failed to unlink device');
      }
    } catch (error) {
      console.error('Error unlinking device:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!auth) return;
  
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/login');
      } else {
        fetchDevices();
        fetchAgeSettings();
        fetchSubscriptionDetails();
      }
    });
  
    return () => unsubscribe();
  }, [auth, navigate]);

  return (
    <DashboardLayout>
      <div className="space-y-8">
        {/* Header */}
        <h2 className="text-3xl font-bold text-amber-900">
          Willkommen bei deiner Abenteuerkiste
        </h2>

        {/* Ersteinrichtungs-Banner */}
        <div className="bg-gradient-to-r from-amber-500 to-amber-600 rounded-xl shadow-lg p-6 text-white">
          <div className="flex items-start gap-4">
            <div className="bg-white/20 rounded-full p-3">
              <Wrench className="w-8 h-8" />
            </div>
            <div className="flex-grow">
              <h3 className="text-xl font-bold mb-2">Ersteinrichtung deiner Abenteuerkiste</h3>
              <p className="text-amber-100 mb-4">
                Bevor du mit den Geschichten loslegen kannst, lass uns deine Abenteuerkiste einrichten. 
                Folge unserer Schritt-für-Schritt-Anleitung, um deine Box mit dem WLAN zu verbinden.
              </p>
              <button
                onClick={() => navigate('/setup')}
                className="inline-flex items-center gap-2 px-6 py-3 bg-white text-amber-600 rounded-lg hover:bg-amber-50 transition-colors duration-200 font-semibold"
              >
                <span>Zur Einrichtung</span>
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Hauptbereich: Abentuerkiste und Stunden nebeneinander */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Abenteuerkiste */}
          <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6">
            <div className="flex items-center gap-3 mb-6">
              <Box className="w-6 h-6 text-amber-600" />
              <h3 className="text-xl font-bold text-amber-900">Deine Abenteuerkiste</h3>
            </div>

            {Array.isArray(devices) && devices.length === 0 ? (
              <p className="text-amber-700">Keine Abenteuerkiste verbunden</p>
            ) : (
              <div className="space-y-4">
                {Array.isArray(devices) && devices.map((device) => (
                  <div
                    key={device?.deviceId || 'default'} 
                    className="bg-amber-50 rounded-xl p-6 border border-amber-200 relative"
                  >
                    <div className="flex items-center gap-4">
                      <div className="w-24 h-24 bg-amber-100 rounded-lg flex items-center justify-center border-2 border-amber-300 flex-shrink-0">
                        <Box className="w-12 h-12 text-amber-600" />
                      </div>
                      <div className="flex-grow">
                        <h4 className="font-semibold text-amber-900 text-lg mb-1">
                          Abentuerkiste {device?.chestId}
                        </h4>
                        <div className="flex items-center gap-2 text-amber-600 mb-2">
                          <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                          <span className="text-sm">Verbunden</span>
                        </div>
                        <p className="text-sm text-amber-600 mb-3">
                          Geräte-ID: {device?.deviceId}
                        </p>
                        <button
                          onClick={() => {
                            setSelectedDevice(device);
                            setIsConfirmOpen(true);
                          }}
                          className="inline-flex items-center gap-2 px-3 py-1.5 bg-amber-100 text-amber-700 rounded-lg hover:bg-amber-200 transition-colors duration-200 text-sm"
                        >
                          <Trash2 className="w-4 h-4" />
                          <span>Entfernen</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Stundenübersicht / Abo-Übersicht */}
          <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <Clock className="w-6 h-6 text-amber-600" />
                <h3 className="text-xl font-bold text-amber-900">Deine Geschichtenminuten</h3>
              </div>
            </div>

            {subscriptionLoading ? (
              <p className="text-amber-700">Lade Abo-Daten...</p>
            ) : (
              <>
                {!subscriptionInfo && (
                  <p className="text-amber-700">Keine Abo-Daten verfügbar.</p>
                )}

                {subscriptionInfo && subscriptionInfo.hasSubscription === false && (
                  <div className="bg-amber-50 rounded-lg p-4">
                    <p className="text-amber-700 font-semibold mb-2">
                      Du hast aktuell kein aktives Abo.
                    </p>
                    <button
                      onClick={() => navigate('/subscription')}
                      className="px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200"
                    >
                      Abo abschließen
                    </button>
                  </div>
                )}

                {subscriptionInfo && subscriptionInfo.hasSubscription && (
                  <SubscriptionUsage subscriptionInfo={subscriptionInfo} navigate={navigate} />
                )}
              </>
            )}
          </div>
        </div>

        {/* Alterseinstellungen */}
        <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6">
          <div className="flex items-center gap-3 mb-6">
            <Brain className="w-6 h-6 text-amber-600" />
            <h3 className="text-xl font-bold text-amber-900">Altersgerechte Einstellungen</h3>
          </div>
          
          <div className="space-y-6">
            {/* Buttons Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {Object.entries(ageGroups).map(([key, group]) => (
                <button
                  key={key}
                  onClick={() => setSelectedAgeGroup(key)}
                  className={`p-4 rounded-lg border ${
                    selectedAgeGroup === key 
                      ? 'border-amber-600 bg-amber-50' 
                      : 'border-gray-200 hover:border-amber-300'
                  } transition-all duration-200`}
                >
                  <h4 className="font-bold text-amber-900 mb-2">{group.label}</h4>
                  <p className="text-sm text-amber-700">{group.description}</p>
                </button>
              ))}
            </div>

            {/* Detailed Information Section */}
            <div className="bg-amber-50 rounded-xl p-6 border border-amber-200">
              <h3 className="text-xl font-bold text-amber-900 mb-6">
                {ageGroups[selectedAgeGroup].label} - Detaillierte Einstellungen
              </h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Left Column */}
                <div className="space-y-6">
                  <div className="flex items-start gap-4">
                    <div className="bg-amber-100 rounded-lg p-2">
                      <Clock className="w-5 h-5 text-amber-700" />
                    </div>
                    <div>
                      <h4 className="font-semibold text-amber-900 mb-1">Geschichtenlänge</h4>
                      <p className="text-amber-700">{ageGroups[selectedAgeGroup].settings.length}</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="bg-amber-100 rounded-lg p-2">
                      <Book className="w-5 h-5 text-amber-700" />
                    </div>
                    <div>
                      <h4 className="font-semibold text-amber-900 mb-1">Wortschatz & Komplexität</h4>
                      <p className="text-amber-700">
                        Wortschatz: {ageGroups[selectedAgeGroup].settings.vocabulary}<br />
                        Komplexität: {ageGroups[selectedAgeGroup].settings.complexity}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="bg-amber-100 rounded-lg p-2">
                      <Star className="w-5 h-5 text-amber-700" />
                    </div>
                    <div>
                      <h4 className="font-semibold text-amber-900 mb-1">Themen</h4>
                      <p className="text-amber-700">
                        {ageGroups[selectedAgeGroup].settings.themes.join(', ')}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Right Column */}
                <div className="space-y-6">
                  <div>
                    <h4 className="font-semibold text-amber-900 mb-2">Erzählstruktur</h4>
                    <p className="text-amber-700">
                      {ageGroups[selectedAgeGroup].settings.details.narrative}
                    </p>
                  </div>

                  <div>
                    <h4 className="font-semibold text-amber-900 mb-2">Charaktere</h4>
                    <p className="text-amber-700">
                      {ageGroups[selectedAgeGroup].settings.details.characters}
                    </p>
                  </div>

                  <div>
                    <h4 className="font-semibold text-amber-900 mb-2">Lernaspekte</h4>
                    <p className="text-amber-700">
                      {ageGroups[selectedAgeGroup].settings.details.educational}
                    </p>
                  </div>

                  <div>
                    <h4 className="font-semibold text-amber-900 mb-2">Interaktion</h4>
                    <p className="text-amber-700">
                      {ageGroups[selectedAgeGroup].settings.details.interaction}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Einstellungen speichern */}
            <div className="flex justify-end mt-6">
              <button
                onClick={() => updateAgeSettings({ ageCategory: selectedAgeGroup })}
                className="px-6 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500
                         transition-colors duration-200 flex items-center gap-2"
              >
                <Settings className="w-4 h-4" />
                <span>Einstellungen speichern</span>
              </button>
            </div>
          </div>
        </div>

        {/* Sicherheitshinweis */}
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-start gap-3">
          <Shield className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
          <p className="text-blue-700 text-sm">
            Die Inhalte werden automatisch an die gewählte Altersgruppe angepasst. 
            Alle Geschichten werden auf altersgerechte Sprache und Themen geprüft.
          </p>
        </div>

        {/* Confirmation Modal */}
        <ConfirmationModal
          isOpen={isConfirmOpen}
          onClose={() => setIsConfirmOpen(false)}
          onConfirm={handleUnlinkDevice}
          isLoading={isLoading}
        />
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;