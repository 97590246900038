import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { Home, User, Settings, CreditCard, Book, HelpCircle } from 'lucide-react';

const DashboardLayout = ({ children }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const menuItems = [
    { icon: <Home className="w-5 h-5" />, label: 'Dashboard', path: '/dashboard' },
    { icon: <User className="w-5 h-5" />, label: 'Konto', path: '/account' },
    { icon: <CreditCard className="w-5 h-5" />, label: 'Abo verwalten', path: '/subscription' },
    { icon: <Book className="w-5 h-5" />, label: 'Einrichtung', path: '/setup' },
    { icon: <HelpCircle className="w-5 h-5" />, label: 'FAQ', path: '/faq' },
  ];

  return (
    <div className="min-h-screen bg-amber-50 flex">
      {/* Sidebar */}
      <div className="w-64 bg-gradient-to-b from-amber-800 to-amber-900 text-white">
        {/* Logo Section */}
        <div className="p-4 border-b border-amber-700/50">
          <div className="flex items-center gap-3 mb-2">
            <img
              src="/box.png"
              alt="Abenteuerkiste Logo"
              className="h-10 w-10 object-contain"
            />
            <h1 className="text-xl font-['Luminari'] text-amber-100 tracking-wide">
              Abenteuerkiste
            </h1>
          </div>
        </div>

        {/* Navigation */}
        <div className="p-4 space-y-6">
          <nav className="space-y-1">
            {menuItems.map((item) => (
              <button
                key={item.path}
                onClick={() => navigate(item.path)}
                className={`w-full flex items-center gap-3 px-4 py-2.5 rounded-lg
                         transition-all duration-200 hover:translate-x-1
                         ${location.pathname === item.path 
                           ? 'bg-amber-700/50 text-white' 
                           : 'text-amber-100 hover:bg-amber-700/50'}`}
              >
                {item.icon}
                <span className="font-medium">{item.label}</span>
              </button>
            ))}
          </nav>

          {/* Logout Button */}
          <div className="pt-4 border-t border-amber-700/50">
            <button
              onClick={handleLogout}
              className="w-full px-4 py-2.5 bg-amber-950/50 text-amber-100
                       hover:bg-amber-950 rounded-lg transition-all duration-200
                       flex items-center justify-center gap-2"
            >
              Abmelden
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-8 overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;